import { environment } from 'src/environments/environment';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { ApiCodeMessage } from 'src/app/shared/consts/api-code-message.constant';
import {catchError, map, switchMap} from 'rxjs/operators';
import {
  PersonPageResult,
  PersonEntity,
  PersonRequest,
  AutoServices,
  DocumentosDriver,
  PersonDriveRequest,
  DriverForMapRequest,
  ChoferCarroRequest,
  DriverTripRequest,
  BirthdayRequest, CarInspectionRequest, CarInspectionModel,
} from 'src/app/shared/models/person-model';
import { DomSanitizer } from "@angular/platform-browser";
import { AdressPerson } from "../components/manage-address-user/manage-address-user.component";
import {Clipboard} from "@angular/cdk/clipboard";
import { NotificationService } from 'src/app/shared/services/notification.service';
import heic2any from "heic2any";

@Injectable({
  providedIn: 'root',
})
export class PersonService {

  private updateDeletePageDoc = new BehaviorSubject<boolean>(false);
  updateDeletePageDoc$ = this.updateDeletePageDoc.asObservable();

  private updateAprovedPageDoc = new BehaviorSubject<boolean>(false);
  updateAprovedPageDoc$ = this.updateAprovedPageDoc.asObservable();

  private createUserAction = new Subject<boolean>();
  createUserAction$ = this.createUserAction.asObservable();

  // @ts-ignore
  private userName = new BehaviorSubject<string>(null);
  userName$ = this.userName.asObservable();

  // @ts-ignore
  private telefonoCliente = new BehaviorSubject<string>(null);
  telefonoCliente$ = this.telefonoCliente.asObservable();

  private formValidAction = new Subject<boolean>();
  formValidAction$ = this.formValidAction.asObservable();

  constructor(private _http: HttpClient, private clipboard: Clipboard, private notificacionService: NotificationService, private domSanitizer: DomSanitizer) {
  }

  actualizacionDeletePageDoc(actualizarpageDoc: boolean) {
    this.updateDeletePageDoc.next(actualizarpageDoc);
  }

  actualizacionAprovedPageDoc(aprovedPageDoc: boolean) {
    this.updateAprovedPageDoc.next(aprovedPageDoc);
  }

  changeCreateUserAction(createUserAction: boolean) {
    this.createUserAction.next(createUserAction);
  }

  setUserName(userName: string) {
    this.userName.next(userName);
  }

  setTelefonoCliente(telefonoCliente: string) {
    this.telefonoCliente.next(telefonoCliente);
  }

  changeFormValidAction(formValidAction: boolean) {
    this.formValidAction.next(formValidAction);
  }

  handleServiceError(error: HttpErrorResponse) {
    let mensajeError = undefined;
    if (error.error instanceof ErrorEvent) {
      console.error('Error:', error.error.message);
      mensajeError = `Error: ${error.error.message}`;
    } else {
      switch (error.status) {
        case 400:
          console.error(`Error del backend, código: ${error.status}, `);
          mensajeError = error.error.error;
          break;
        case 500:
          console.error(`Error del backend, código: ${error.status} `);
          break;
        case 503:
          console.error(`Error del backend, código: ${error.status} `);
          mensajeError = ApiCodeMessage.MSG_CODE_503;
          break;
        default:
          console.error(`Error: ${error} `);
          mensajeError = error;
          break;
      }
    }
    return throwError(mensajeError);
  }

  /** Obtener listado personas */
  getPersons(perReq?: PersonRequest, pageNumber?: number, pageSize?: number, searchParam?: string, sort?: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    perReq?.activo != null
      ? (queryParams = queryParams.append('activo', perReq?.activo))
      : null;
    perReq?.deuda != null
      ? (queryParams = queryParams.append('deuda', perReq?.deuda))
      : null;
    perReq?.esCliente
      ? (queryParams = queryParams.append('esCliente', true))
      : null;
    perReq?.puedeEndeudarse
      ? (queryParams = queryParams.append('puedeEndeudarse', true))
      : null;
    perReq?.esChofer
      ? (queryParams = queryParams.append('esChofer', true))
      : null;
    perReq?.esUsuario
      ? (queryParams = queryParams.append('esUsuario', true))
      : null;
    perReq?.estadoChofer && perReq?.estadoChofer !== 'ALL'
      ? (queryParams = queryParams.append('estadoChofer', perReq.estadoChofer))
      : null;
    pageNumber !== undefined
      ? (queryParams = queryParams.append('pageNumber', pageNumber))
      : null;
    pageSize !== undefined
      ? (queryParams = queryParams.append('pageSize', pageSize))
      : null;
    searchParam
      ? (queryParams = queryParams.append('searchParam', searchParam))
      : null;
    sort
      ? (queryParams = queryParams.append('sort', sort))
      : null;
    const options = {
      headers: headers,
      params: queryParams,
    };

    return this._http.get<any>(environment.servicePerson, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener persona Jefe de turno*/
  getJTurnPersons(): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .get<any>(environment.serviceJTurnPerson, { headers })
      .pipe(
        map((data) => data.result),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener personas segun categoria*/
  getUsersPersons(idCategoria: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .get<any>(environment.servicePerson + '/usuario_oficinas' + `?categoriaId=${idCategoria}`, { headers })
      .pipe(
        map((data) => data.result),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener listado choferes */
  getPersonDrive(perReq?: PersonDriveRequest, pageNumber?: number, pageSize?: number, searchParam?: string, sort?: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    perReq?.activo
      ? (queryParams = queryParams.append('activo', true))
      : null;
    perReq?.habilitado
      ? (queryParams = queryParams.append('habilitado', true))
      : null;
    perReq?.aceptaReservas
      ? (queryParams = queryParams.append('aceptaReservas', true))
      : null;
    pageNumber !== undefined
      ? (queryParams = queryParams.append('pageNumber', pageNumber))
      : null;
    pageSize !== undefined
      ? (queryParams = queryParams.append('pageSize', pageSize))
      : null;
    searchParam
      ? (queryParams = queryParams.append('searchParam', searchParam))
      : null;
    sort
      ? (queryParams = queryParams.append('sort', sort))
      : null;
    const options = {
      headers: headers,
      params: queryParams,
    };

    return this._http.get<any>(environment.serviceDrive, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener listado choferes para mostrar en mapa*/
  getDriversForMap(driverForMapRequest?: DriverForMapRequest, searchParam?: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    driverForMapRequest?.buscarParaMapa
      ? (queryParams = queryParams.append('buscarParaMapa', true))
      : null;
    driverForMapRequest?.estado
      ? (queryParams = queryParams.append('estado', driverForMapRequest?.estado))
      : null;
    driverForMapRequest?.tipoMedio
      ? (queryParams = queryParams.append('tipoMedio', driverForMapRequest?.tipoMedio))
      : null;
    searchParam
      ? (queryParams = queryParams.append('searchParam', searchParam))
      : null;

    const options = {
      headers: headers,
      params: queryParams,
    };

    return this._http.get<any>(environment.serviceDrive, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener listado choferes para mostrar en mapa*/
  checkDriverAcceptTrip(driverTripRequest: DriverTripRequest, personId?: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    const options = {
      headers: headers
    };

    return this._http.post<any>(environment.serviceDrive + `/${personId}/` + 'puede_asignarse', driverTripRequest, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener persona */
  getPersonById(personId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .get<any>(environment.servicePerson + `/${personId}`, { headers })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener chofer por id */
  getDriverById(personId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .get<any>(environment.serviceDrive + `/${personId}`, { headers })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener cliente */
  getClientById(clientId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .get<any>(environment.servicePerson + '/clientes' + `/${clientId}`, { headers })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Adicionar persona */
  addPerson(reqUser: PersonEntity): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .post<any>(environment.servicePerson, reqUser, {
        headers,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleServiceError)
      );
  }

  /** Editar persona */
  editPerson(reqUser: PersonEntity, personId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .put<any>(environment.servicePerson + `/${personId}`, reqUser, {
        headers,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleServiceError)
      );
  }

  getDataColumnsTablePersons() {
    return [
      'activo',
      'identificador',
      'telefCelular',
      'nombreApellidos',
      // 'esChofer',
      // 'nombreUsuario',
      'categorias',
      'puedeEndeudarse',
      'cuenta',
      'saldoUsd',
      'saldoEur',
      'nivelMembresia',
      // 'esUsuarioContratado',
      'versionAppCli',
      //'servicios',
    ];
  }

  getDataColumnsTableUsers() {
    return [
      'activo',
      'identificador',
      'telefCelular',
      'nombreApellidos',
      'categorias',
      'puedeEndeudarse',
      'cuenta',
      'saldoUsd',
      'saldoEur',
      'esUsuarioContratado',
      'versionAppCli'
    ];
  }

  getDataColumnsTableChoferCarro() {
    return [
      'choferHabilitado',
      //'activo',
      'identificador',
      'telefCelular',
      'nombreApellidos',
      'choferEstado',
      'puedeEndeudarse',
      'cuenta',
      'fondoReal',
      'saldoUsd',
      'fondoRealUsd',
      'saldoEur',
      'fondoRealEur',
      'servicios',
      'nombreMarca',
      'nombreModelo',
      'cantPasajMax',
      'versionApp',
      //'tieneLicOperAct',
    ];
  }

  getDataColumnsTableBirthday() {
    return [
      'identificador',
      'fechaCumpleAnnos',
      'diasFaltan',
      'ci',
      'nombreApellidos',
      'telefCelular',
      'tiempoEnAgencia',
    ];
  }

  getDataColumnsAccreditors() {
    return [
      'nombres',
      'apellidos',
      'telefCelular',
    ];
  }

  getDataColumnsEntities() {
    return [
      'nombre',
      'telefCelular',
      'activo'
    ];
  }

  getDataColumnsDriverUnbalanced() {
    return [
      'identificador',
      'nombreApellidos',
      'telefonoCelular',
      'saldoFondo',
      'saldoCuenta'
    ];
  }

  getDataColumnsCarInspection() {
    return [
      'nombreChofer',
      'fechaInspeccion',
      'fechaAviso',
      'fechaProgramada',
      //'deficiencias',
      'darSeguimiento',
      'evaluacionInspeccion',
      'asistencia',
      'matricula',
      'nombreMarca',
      'nombreModelo'
    ];
  }

  getDataColumnsMember() {
    return [
      'nombreApellidos',
      'telefCelular',
      'fechaCaducaMembresia',
      'fechaActivacion',
      'diasFaltan',
    ];
  }

  /** Obtener todas las categorías activas */
  getCategories(): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const queryParams = new HttpParams().append('activa', true);
    const options = {
      headers: headers,
      params: queryParams,
    };

    return this._http.get<any>(environment.serviceCategories, options).pipe(
      map((data) => data),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener todas las categorías inferiores a un rol dado */
  getCategoriesRolesInferiores(categoriaId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const queryParams = new HttpParams().append('categoriaId', categoriaId);
    const options = {
      headers: headers,
      params: queryParams,
    };

    return this._http.get<any>(environment.serviceCategories + '/roles_inferiores', options).pipe(
      map((data) => data),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener datos del perfil de cliente */
  getProfileClient(personId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .get<any>(environment.servicePerson + `/${personId}/` + 'estadisticas?perfil=cliente', { headers })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener datos del perfil de usuario */
  getProfileDriver(personId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .get<any>(environment.servicePerson + `/${personId}/` + 'estadisticas?perfil=chofer', { headers })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener foto de la persona */
  getPhotoPerson(filename: string, type: string, fileType?: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });

    // return this._http
    //   .get<Blob>(environment.serviceImagenes + '?filename=' + `${filename}` + '&type=' + `${type}`
    //     , { headers: headers, responseType: "blob" as "json" })
    //   .pipe(
    //     map(data => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data))),
    //     catchError(this.handleServiceError)
    //   );

    return this._http
      .get<Blob>(environment.serviceImagenes + '?filename=' + `${filename}` + '&type=' + `${type}`, { headers: headers, responseType: "blob" as "json" })
      .pipe(
        switchMap(async (data) => {
          //const fileType = await this.detectFileType(data);
          if (fileType && fileType === 'heic') {
            const convertedBlob = await heic2any({ blob: data, toType: 'image/jpeg' });
            return this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(convertedBlob));
          } else {
            return this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data));
          }
        }),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener foto de la persona */
  addPhotoPerson(filePathname: string, image: any): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    //const options = {headers: new HttpHeaders({'Accept': 'image/jpeg'})};
    //const options = {headers: new HttpHeaders({'Accept': 'multipart/form-data'})};
    const formData = new FormData();
    formData.append('filePathname', filePathname);
    formData.append('image', image);
    return this._http
      .post<any>(environment.serviceImagenes, formData, { headers: headers, responseType: "blob" as "json" })
      .pipe(
        map(data => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data))),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener foto de la persona */
  deletePhotoPerson(filename: string, type: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });

    return this._http
      .delete<any>(environment.serviceImagenes + '?filename=' + `${filename}` + '&type=' + `${type}`
        , { headers: headers })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener direcciones de la persona */
  getAdressPerson(personId: number, pageNumber?: number, pageSize?: number): Observable<any> {

    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    pageNumber !== undefined
      ? (queryParams = queryParams.append('pageNumber', pageNumber))
      : null;
    pageSize !== undefined
      ? (queryParams = queryParams.append('pageSize', pageSize))
      : null;

    const options = {
      headers: headers,
      params: queryParams,
    };

    return this._http
      .get<any>(environment.serviceClient + `/${personId}/` + 'direcciones', options)
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Adicionar direcciones de la persona */
  addAdressPerson(personId: number, reqAdressPerson: AdressPerson): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .post<any>(environment.serviceClient + `/${personId}/` + 'direcciones', reqAdressPerson, { headers })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Modificar direcciones de la persona */
  editAdressPerson(personId: number, adressId: number, reqAdressPerson: AdressPerson): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .put<any>(environment.serviceClient + `/${personId}/` + 'direcciones' + `/${adressId}`, reqAdressPerson, { headers })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Eliminar direcciones de la persona */
  deleteAdressPerson(personId: number, adressId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .delete<any>(environment.serviceClient + `/${personId}/` + 'direcciones' + `/${adressId}`, { headers })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener autos y servicios del chofer */
  getAutServDriver(personId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .get<any>(environment.servicePerson + `/${personId}/` + 'autos', { headers })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }
  /** Obtener documentos del chofer */
  loadDocDriver(personId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .get<any>(environment.servicePerson + `/${personId}/` + 'documentos', { headers })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener tipos de documentos */
  loadConfDocDriver(): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .get<any>(environment.serviceConfDoc + '?activa=true&mostrarChofer=true', { headers })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }


  /** Obtener todas las marcas de autos */
  getMarks(): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });

    return this._http.get<any>(environment.serviceMarks, { headers }).pipe(
      map((data) => data),
      catchError(this.handleServiceError)
    );
  }
  /** Obtener todas las modelos de autos */
  getModels(idMark: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });

    return this._http.get<any>(environment.serviceMarks + `/${idMark}/` + 'modelos', { headers }).pipe(
      map((data) => data),
      catchError(this.handleServiceError)
    );
  }
  /** Obtener todas las colores de autos */
  getColors(): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });

    return this._http.get<any>(environment.serviceColors, { headers }).pipe(
      map((data) => data),
      catchError(this.handleServiceError)
    );
  }
  /** Obtener los tipos de servicios del chofer */
  getServices(): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });

    return this._http.get<any>(environment.serviceAllServices + '?esCaractAuto=true', { headers }).pipe(
      map((data) => data),
      catchError(this.handleServiceError)
    );
  }
  /** Adicionar auto y servicios al chofer */
  addAutServDriver(personId: number, reqAutoServices: AutoServices): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .post<any>(environment.servicePerson + `/${personId}/` + 'autos', reqAutoServices, { headers })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }
  /** Modificar auto y servicios al chofer */
  editAutServDriver(personId: number, autoId: number, reqAutoServices: AutoServices): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .put<any>(environment.servicePerson + `/${personId}/` + 'autos' + `/${autoId}`, reqAutoServices, { headers })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }
  /** Eliminar auto y servicios al chofer */
  deleteAutServDriver(personId: number, autoId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .delete<any>(environment.servicePerson + `/${personId}/` + 'autos' + `/${autoId}`, { headers })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }
  /** Adicionar documentos al chofer */
  addDocDriver(personId: number, reqDocumentosDriver: DocumentosDriver): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .post<any>(environment.servicePerson + `/${personId}/` + 'documentos', reqDocumentosDriver, { headers })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }
  /** Modificar documentos al chofer */
  editDocDriver(personId: number, documentId: number, reqDocumentosDriver: any): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .put<any>(environment.servicePerson + `/${personId}/` + 'documentos' + `/${documentId}`, reqDocumentosDriver, { headers })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }
  /** Eliminar documento al chofer */
  deleteDocDriver(personId: number, documentId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    return this._http
      .delete<any>(environment.servicePerson + `/${personId}/` + 'documentos' + `/${documentId}`, { headers })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /**
   * Metodo para seleccionar un auto como activo
   * @param personId
   * @param autoId
   */
  markActiveCar(personId: number, autoId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .patch<any>(environment.servicePerson + `/${personId}/` + 'autos' +  `/${autoId}/` + 'marcarSeleccionado', {
        headers,
      })
      .pipe(
        map((res) => res.result),
        catchError(this.handleServiceError)
      );
  }

  /**
   * Metodo para activar un chofer
   * @param personId
   * @param autoId
   */
  markActiveDriver(personId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .patch<any>(environment.serviceDrive + `/${personId}/` + 'habilitar_con_auto', {
        headers,
      })
      .pipe(
        map((res) => res.result),
        catchError(this.handleServiceError)
      );
  }

  /**
   * Metodo para cambiar de estado a Activo a un chofer si esta en estado ocupado  y no tiene viajes en proceso
   * Retorna la lista de viajes en proceso.
   * @param personId
   */
  changeDriverState(personId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .patch<any>(environment.serviceDrive + `/${personId}/` + 'activar', {
        headers,
      })
      .pipe(
        map((res) => res.result),
        catchError(this.handleServiceError)
      );
  }

  /**
   * Metodo para desactivar un chofer
   * @param personId
   * @param autoId
   */
  markInactiveDriver(personId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .patch<any>(environment.serviceDrive + `/${personId}/` + 'deshabilitar', {
        headers,
      })
      .pipe(
        map((res) => res.result),
        catchError(this.handleServiceError)
      );
  }

  /**
   * Metodo que obtiene si el servicio de moto para un chofer dado
   * es de tipo cortesia chofer
   * @param choferId
   * @return true or false
   */
  showDriverCourtesy(choferId: number, viajeId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    viajeId && viajeId !== -1
      ? (queryParams = queryParams.append('idViaje', viajeId))
      : null;

      const options = {
        headers: headers,
        params: queryParams,
      };

    return this._http
      .get<any>(environment.serviceDrive + `/${choferId}/` + 'mostrar_cortesia_chofer_moto', options)
      .pipe(
        map((res) => res),
        catchError(this.handleServiceError)
      );
  }

  /**
   * Metodo que verifica si la suma de los servicios reserva del chofer + el saldo es mayor a 0
   * @param choferId
   * @param saldoTotal
   * @return true or false
   */
  checkReserva(choferId: number, saldoTotal: number, moneda: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    saldoTotal
      ? (queryParams = queryParams.append('saldoTotal', saldoTotal))
      : null;

    moneda
      ? (queryParams = queryParams.append('moneda', moneda))
      : null;

      const options = {
        headers: headers,
        params: queryParams,
      };

    return this._http
      .get<any>(environment.serviceDrive + `/${choferId}/` + 'chofer_check_saldo_reserva', options)
      .pipe(
        map((res) => res),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener datos país */
  loadPaisNomenTelef(): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http
      .get<any>(environment.servicePaisNomenTelef, { headers })
      .pipe(
        map((data) => data.result),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener datos país */
  loadPaisNomenCodigoTelef(): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http
      .get<any>(environment.servicePaisNomenTelef + '/codigo', { headers })
      .pipe(
        map((data) => data.result),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener listado de choferes y carros */
  getPersonsChoferCarro(perReq?: ChoferCarroRequest, pageNumber?: number, pageSize?: number, searchParam?: string, sort?: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    perReq?.activo
      ? (queryParams = queryParams.append('activo', true))
      : null;
    perReq?.deuda
      ? (queryParams = queryParams.append('deuda', true))
      : null;
    perReq?.marca
      ? (queryParams = queryParams.append('marca', perReq.marca))
      : null;
    perReq?.modelo
      ? (queryParams = queryParams.append('modelo', perReq.modelo))
      : null;
    perReq?.color
      ? (queryParams = queryParams.append('color', perReq.color))
      : null;
    perReq?.factor
      ? (queryParams = queryParams.append('factor', perReq.factor))
      : null;
    pageNumber !== undefined
      ? (queryParams = queryParams.append('pageNumber', pageNumber))
      : null;
    pageSize !== undefined
      ? (queryParams = queryParams.append('pageSize', pageSize))
      : null;
    searchParam
      ? (queryParams = queryParams.append('searchParam', searchParam))
      : null;
    sort
      ? (queryParams = queryParams.append('sort', sort))
      : null;
    const options = {
      headers: headers,
      params: queryParams,
    };

    return this._http.get<any>(environment.serviceDrive + '/web', options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener listado de cumple de las personas registradas */
  getBirthdays(birthdayRequest: BirthdayRequest, pageNumber?: number, pageSize?: number, searchParam?: string, sort?: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    birthdayRequest.mes
      ? (queryParams = queryParams.append('mes', birthdayRequest.mes))
      : null;
    pageNumber !== undefined
      ? (queryParams = queryParams.append('pageNumber', pageNumber))
      : null;
    pageSize !== undefined
      ? (queryParams = queryParams.append('pageSize', pageSize))
      : null;
    searchParam
      ? (queryParams = queryParams.append('searchParam', searchParam))
      : null;
    sort
      ? (queryParams = queryParams.append('sort', sort))
      : null;
    const options = {
      headers: headers,
      params: queryParams,
    };

    return this._http.get<any>(environment.servicePerson + '/cumple_annos', options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener listado de personas acreditadores */
  getAccreditorsBySearchParams(searchParam: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    searchParam !== undefined
      ? (queryParams = queryParams.append('searchParam', searchParam))
      : null;

      const options = {
      headers: headers,
      params: queryParams,
    };

    return this._http.get<any>(environment.serviceAccreditor, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener listado de personas acreditadores */
  getAccreditors(pageNumber?: number, pageSize?: number, sort?: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    pageNumber !== undefined
      ? (queryParams = queryParams.append('pageNumber', pageNumber))
      : null;
    pageSize !== undefined
      ? (queryParams = queryParams.append('pageSize', pageSize))
      : null;
    sort
      ? (queryParams = queryParams.append('sort', sort))
      : null;

      const options = {
      headers: headers,
      params: queryParams,
    };

    return this._http.get<any>(environment.serviceAccreditor, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Eliminar acreditador */
  deleteAccreditor(personId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .delete<any>(environment.serviceAccreditor + `/${personId}`, { headers })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Eliminar entidad */
  deleteEntity(entityId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .delete<any>(environment.servicePersonEntity + `/${entityId}`, { headers })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener listado de choferes descuadrados */
  getUnbalancedDriver(idInicial: number, idFinal: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    idInicial !== undefined
      ? (queryParams = queryParams.append('idInicial', idInicial))
      : null;
    idFinal !== undefined
      ? (queryParams = queryParams.append('idFinal', idFinal))
      : null;

    const options = {
      headers: headers,
      params: queryParams,
    };

    return this._http.get<any>(environment.serviceDrive + '/fondo_descuadrado', options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  buildDeudaCopy(selectedChoferPerson: PersonEntity) {
    let deudaCopy: string = '';

    const saludo = "Somos ReneTaxis: ";
    const mensaje = "Le informamos que tiene una Deuda con nosotros de ";
    const mensaje1 = " y por tanto está trabajando en negativo."
    const desactivado = "Ha sido temporalmente desactivado del sistema";
    const deposito = "Le pedimos por favor que realice el depósito lo antes posible."
    const saludos = "Saludos y muchas gracias.";

    let decorator = '*';

    let saldo = 0;
    if (selectedChoferPerson && selectedChoferPerson.cuenta) {
      saldo = Math.abs(selectedChoferPerson.cuenta.saldo);
    }

    deudaCopy += saludo + "\n\n";
    deudaCopy += mensaje + decorator + formatPrice(saldo) + decorator +' '+ decorator + 'CUP' + decorator + mensaje1 + "\n\n";
    deudaCopy += decorator + desactivado + decorator + "\n\n";
    deudaCopy += deposito + "\n\n";
    deudaCopy += saludos;
    deudaCopy += "\n";

    this.clipboard.copy(deudaCopy);
    /*this.notificacionService.notificationSuccess(
      'Copiado al portapapeles.'
    );*/
  }

  buildDeudaClienteCopy(selectedChoferPerson: PersonEntity) {
    let deudaCopy = '';
    const saludo = "Hola ";
    const mensaje = " somos ReneTaxis: ";
    const mensaje1 = "Le informamos que usted presenta una deuda con la Agencia de ";
    const mensaje2 = "Nos deja saber cuando pudiera abonarnos el pago.";
    const cup = "CUP";
    const saludos = "Saludos";
    const decorator = '*';
    let saldo = 0;

    if (selectedChoferPerson && selectedChoferPerson.cuenta) {
      saldo = Math.abs(selectedChoferPerson.cuenta.saldo);
    }

    let nombre = selectedChoferPerson?.nombreApellidos || '';

    deudaCopy += `${saludo}${decorator}${nombre}${decorator},${mensaje}😊:\n\n`;
    deudaCopy += `${mensaje1}${decorator}${formatPrice(saldo)}${decorator} ${decorator}${cup}${decorator}. ${mensaje2}\n\n`;
    deudaCopy += `${saludos}\n`;

    this.clipboard.copy(deudaCopy);
  }
  /**
   *
   * @param categoria Obtiene las personas de una categoria especifica
   * @returns
   */
  getPersonByCategory(categoria: string): Observable<any> {

    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    categoria
      ? (queryParams = queryParams.append('categoria', categoria))
      : null;

    const options = {
      headers: headers,
      params: queryParams,
    };
    return this._http.get<any>(environment.servicePerson + '/jefes_turno', options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  getPersonsEntityBySearchParams(searchParam: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    searchParam
      ? (queryParams = queryParams.append('searchParam', searchParam))
      : null;
    queryParams = queryParams.append('activo', true);

    const options = {
      headers: headers,
      params: queryParams,
    };

    return this._http.get<any>(environment.servicePersonEntity, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener listado de entidades */
  getPersonsEntity(pageNumber?: number, pageSize?: number, searchParam?: string, sort?: string, activo?: boolean): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    pageNumber !== undefined
      ? (queryParams = queryParams.append('pageNumber', pageNumber))
      : null;
    pageSize !== undefined
      ? (queryParams = queryParams.append('pageSize', pageSize))
      : null;
    searchParam
      ? (queryParams = queryParams.append('searchParam', searchParam))
      : null;
    sort
      ? (queryParams = queryParams.append('sort', sort))
      : null;
    activo != undefined
      ? (queryParams = queryParams.append('activo', activo))
      : (queryParams = queryParams.append('activo', true));
    const options = {
      headers: headers,
      params: queryParams,
    };

    return this._http.get<any>(environment.servicePersonEntity, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener entidad por id */
  getEntityById(personId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .get<any>(environment.servicePersonEntity + `/${personId}`, { headers })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener listado de personas vinculadas entidad */
  getPersonasVinculadas(entidadId: number, pageNumber?: number, pageSize?: number, sort?: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    pageNumber !== undefined
      ? (queryParams = queryParams.append('pageNumber', pageNumber))
      : null;
    pageSize !== undefined
      ? (queryParams = queryParams.append('pageSize', pageSize))
      : null;
    sort
      ? (queryParams = queryParams.append('sort', sort))
      : null;
    entidadId !== undefined
      ? (queryParams = queryParams.append('entidadId', entidadId))
      : null;

    const options = {
      headers: headers,
      params: queryParams,
    };

    return this._http.get<any>(environment.servicePersonVinculadaEntity , options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener listado de personas vinculadas entidad */
  getEntityRelatedPerson(entityId?: number, pageNumber?: number, pageSize?: number, sort?: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();
    entityId !== undefined
      ? (queryParams = queryParams.append('entidadId', entityId))
      : null;
    // pageNumber !== undefined
    //   ? (queryParams = queryParams.append('pageNumber', pageNumber))
    //   : null;
    // pageSize !== undefined
    //   ? (queryParams = queryParams.append('pageSize', pageSize))
    //   : null;
    // sort
    //   ? (queryParams = queryParams.append('sort', sort))
    //   : null;

    const options = {
      headers: headers,
      params: queryParams,
    };

    return this._http.get<any>(environment.servicePersonVinculadaEntity, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener listado de Inspecciones de autos*/
  getCarInspection(carInspectionRequest: CarInspectionRequest, pageNumber: number, pageSize: number, sort: string, searchParam: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });

    let queryParams = new HttpParams();

    carInspectionRequest.fechaInicio
      ? (queryParams = queryParams.append('fechaInicio', carInspectionRequest.fechaInicio))
      : null;
    carInspectionRequest.fechaFin
      ? (queryParams = queryParams.append('fechaFin', carInspectionRequest.fechaFin))
      : null;
    searchParam
      ? (queryParams = queryParams.append('searchParam', searchParam))
      : null;
    pageNumber !== undefined
      ? (queryParams = queryParams.append('pageNumber', pageNumber))
      : null;
    pageSize !== undefined
      ? (queryParams = queryParams.append('pageSize', pageSize))
      : null;
    sort !== undefined
      ? (queryParams = queryParams.append('sort', sort))
      : null;

    const options = {
      headers: headers,
      params: queryParams,
    };
    return this._http.get<any>(environment.serviceCarInspection + `/`, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener datos de Inspección de auto por ID*/
  getCarInspectionByID(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const options = {
      headers: headers,
    };
    return this._http.get<any>(environment.serviceCarInspection + `/${id}`, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Adicionar Inspección de auto */
  addCarInspection(carInspectionModel: CarInspectionModel): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    const options = {
      headers: headers,
    };
    return this._http.post<any>(environment.serviceCarInspection, carInspectionModel, options).pipe(
      map((res) => res),
      catchError(this.handleServiceError)
    );
  }

  /** Editar Inspección de auto */
  editCarInspection(carInspectionModel: CarInspectionModel, id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http.put<any>(environment.serviceCarInspection + `/${id}`, carInspectionModel, {
      headers,
    }).pipe(map((res) => res),
      catchError(this.handleServiceError)
    );
  }

  /** Eliminar Inspección de auto */
  deleteCarInspection(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http.delete<any>(environment.serviceCarInspection + `/${id}/`, { headers })
      .pipe(map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener listado de Miembros*/
  getMembers(pageNumber: number, pageSize: number, sort: string, searchParam: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });

    let queryParams = new HttpParams();

    searchParam
      ? (queryParams = queryParams.append('searchParam', searchParam))
      : null;
    pageNumber !== undefined
      ? (queryParams = queryParams.append('pageNumber', pageNumber))
      : null;
    pageSize !== undefined
      ? (queryParams = queryParams.append('pageSize', pageSize))
      : null;
    sort !== undefined
      ? (queryParams = queryParams.append('sort', sort))
      : null;

    const options = {
      headers: headers,
      params: queryParams,
    };
    return this._http.get<any>(environment.serviceMembership, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener el historial de pagos de membresía dado el id de la persona*/
  getMemberHistoryByID(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    let queryParams = new HttpParams();
    queryParams = queryParams.append('personaId', id);
    const options = {
      headers: headers,
      params: queryParams,
    };
    return this._http.get<any>(environment.serviceMemberHistory, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /**Renovar subscripción*/
  renewMemberSubscription(personId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .patch<any>(environment.serviceRenewMembership  + `?personaId=${personId}` , {
        headers,
      })
      .pipe(
        map((res) => res.result),
        catchError(this.handleServiceError)
      );
  }

  /**Cancelar subscripción*/
  cancelMemberSubscription(personId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .patch<any>(environment.serviceCancelMembership + `?personaId=${personId}`, {
        headers,
      })
      .pipe(
        map((res) => res.result),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener listado de sitios frecuentes*/
  getFrequentSites(pageNumber: number, pageSize: number, direccion?: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    pageNumber !== undefined
      ? (queryParams = queryParams.append('pageNumber', pageNumber))
      : null;
    pageSize !== undefined
      ? (queryParams = queryParams.append('pageSize', pageSize))
      : null;
    direccion !== undefined
      ? (queryParams = queryParams.append('direccion', direccion))
      : null;

    const options = {
      headers: headers,
      params: queryParams,
    };

    return this._http
      .get<any>(environment.serviceFrequentSites, options)
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Adicionar Sitio frecuente */
  addFrequentSite(reqAdressPerson: AdressPerson): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    const options = {
      headers: headers,
    };
    return this._http.post<any>(environment.serviceFrequentSites, reqAdressPerson, options).pipe(
      map((res) => res),
      catchError(this.handleServiceError)
    );
  }

  /** Editar Sitio frecuente*/
  editFrequentSite(reqAdressPerson: AdressPerson, id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http.put<any>(environment.serviceFrequentSites + `/${id}`, reqAdressPerson, {
      headers,
    }).pipe(map((res) => res),
      catchError(this.handleServiceError)
    );
  }

  /** Eliminar Sitio frecuente */
  deleteFrequentSite(id: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http.delete<any>(environment.serviceFrequentSites + `/${id}/`, { headers })
      .pipe(map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener mensaje de cumpleaños dado id persona */
  getBirthayMessageByIdPerson(personaId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    let queryParams = new HttpParams();
    personaId
      ? (queryParams = queryParams.append('personaId', personaId))
      : null;
    const options = {
      headers: headers,
      params: queryParams,
    };
    return this._http.get<any>(environment.serviceBirthay , options).pipe(
      map((data) => data),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener marcas de autos excluidas */
  getMarcasExcluidas(personId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .get<any>(environment.servicePerson + `/${personId}/` + 'marcas_autos_excluidas', { headers })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Obtiene la lista de choferes auditores */
  getChoferesAuditores(): Observable<any> {
    const headers = new HttpHeaders({
      accept:'application/json',
    });

    const options = {
      headers: headers,
    };
    return this._http.get<any>(environment.serviceDrive + '/auditores', options).pipe(
      map((data) => data),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener Codificador Orden Deudas */
  getOrdenDeudas() {
    return [
      {
        id: 1,
        nombre: 'Más recientes',
        descCorta: 'RECIENTES'
      },
      {
        id: 2,
        nombre: 'Más antiguas',
        descCorta: 'ANTIGUAS'
      },
      {
        id: 3,
        nombre: 'Mayor monto',
        descCorta: 'MAYOR_MONTO'
      }
    ];
  }

}

function formatPrice(price: number): string {
  const formattedPrice = price.toFixed(2); // Asegura que siempre tenga dos decimales
  const parts = formattedPrice.split('.');

  if (parts.length === 1) {
    // Si no hay parte decimal, agrega los ceros
    return `${formattedPrice}.00`;
  } else {
    // Si hay parte decimal, verifica si es necesario agregar ceros
    const decimalPart = parts[1];
    if (decimalPart.length === 1) {
      return `${formattedPrice}0`; // Agrega un cero si solo hay un dígito decimal
    } else {
      return formattedPrice; // Mantén el formato original si hay más de un dígito decimal
    }
  }
}
